export const GET_BATTLES_REQUESTED = 'battles/GET_BATTLES_REQUESTED';
export const GET_BATTLES_SUCCESS = 'battles/GET_BATTLES_SUCCESS';
export const GET_BATTLES_FAILURE = 'battles/GET_BATTLES_FAILURE';

export const GET_QUESTIONS_REQUESTED = 'battles/GET_QUESTIONS_REQUESTED';
export const GET_QUESTIONS_SUCCESS = 'battles/GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'battles/GET_QUESTIONS_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  battlesList: [],
  pageCount: 0,
  questionsDetails: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BATTLES_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BATTLES_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        battlesList: action.result.battlesDetailsList.battlesDetails,
        pageCount: action.result.battlesDetailsList.total
      };
    }
    case GET_BATTLES_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        questionsDetails: action.result
      };
    }
    case GET_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getBattlesList = (data) => {
  return {
    types: [GET_BATTLES_REQUESTED, GET_BATTLES_SUCCESS, GET_BATTLES_FAILURE],
    promise: client => client.get(`getBattlesList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&filterStatus=${data.filterStatus}&startDate=${data.startDate}&endDate=${data.endDate}&rewardType=${data.rewardType}`)
  };
};

export const getQuestionDetails = (data) => {
  return {
    types: [GET_QUESTIONS_REQUESTED, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    promise: client => client.post('getQuestionDetails', {
      data
    })
  };
};
