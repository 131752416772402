import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Loadable from 'react-loadable';
import OnRouteChange from '@containers/common/OnRouteChange';
import App from '@containers/App';
import PageLoader from '@components/common/PageLoader';
import { APP_CONFIG } from '@constants';
import Footer from '@containers/common/Footer';
// import Header from '@components/common/Header';
import SideMenu from '@components/common/SideMenu/navbar';
import 'font-awesome/css/font-awesome.min.css';
import EN from './localization/EN';
import PrivateRoute from './containers/PrivateRoute';

// const PrivateRoute = ({ component: Component, authed, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={props => (typeof window.localStorage.getItem('access_token') !== 'undefined'
//         && window.localStorage.getItem('access_token') !== null
//         && window.localStorage.getItem('access_token') !== '' ? (
//           <>
//             {/* <Header /> */}
//             <SideMenu />
//             <Component {...props} />
//           </>
//         ) : (
//           <Redirect to={{ pathname: '/', state: { from: props.location } }} />
//         ))
//       }
//     />
//   );
// };



const LoadingComponent = ({ isLoading, isError }) => {
  if (isLoading) {
    return <PageLoader />;
  }
  if (isError) {
    return (
      <div>
        {EN.SORRY}
      </div>
    );
  }
  return null;
};


const Default = Loadable({
  loader: () => import('@containers/Default'),
  loading: LoadingComponent,
});

const notFound = Loadable({
  loader: () => import('@components/404NotFound'),
  loading: LoadingComponent,
});

const Login = Loadable({
  loader: () => import('@containers/Login'),
  loading: LoadingComponent,
});

const Home = Loadable({
  loader: () => import('@containers/Home'),
  loading: LoadingComponent,
});

const Register = Loadable({
  loader: () => import('@containers/Register'),
  loading: LoadingComponent,
});

const Logout = Loadable({
  loader: () => import('@containers/Logout'),
  loading: LoadingComponent,
});

// const Dashboard = Loadable({
//   loader: () => import('@containers/Dashboard'),
//   loading: LoadingComponent,
// });

const DemoDashboard = Loadable({
  loader: () => import('@containers/DemoDashboard'),
  loading: LoadingComponent,
});

const PaymentProcessing = Loadable({
  loader: () => import('@containers/PaymentProcessing'),
  loading: LoadingComponent,
});

const ManageCourse = Loadable({
  loader: () => import('@containers/ManageCourse'),
  loading: LoadingComponent,
});

const PayemtPage = Loadable({
  loader: () => import('@containers/payemtPage'),
  loading: LoadingComponent,
});

const TransactionDetails = Loadable({
  loader: () => import('@containers/TransactionDetails'),
  loading: LoadingComponent,
});

const AddBeneficiary = Loadable({
  loader: () => import('@containers/AddBeneficiary'),
  loading: LoadingComponent,
});

const Tutorial = Loadable({
  loader: () => import('@containers/Tutorial'),
  loading: LoadingComponent,
});

const AddTutorial = Loadable({
  loader: () => import('@containers/AddTutorial'),
  loading: LoadingComponent,
});


const UploadContent = Loadable({
  loader: () => import('@containers/ContentUpload'),
  loading: LoadingComponent,
});

const Tournaments = Loadable({
  loader: () => import('@containers/Tournaments'),
  loading: LoadingComponent,
});

const UploadConfig = Loadable({
  loader: () => import('@containers/ConfigUpload'),
  loading: LoadingComponent,
});

const questionList = Loadable({
  loader: () => import('@containers/QuestionList'),
  loading: LoadingComponent,
});

const ViewAs = Loadable({
  loader: () => import('@containers/ViewAs'),
  loading: LoadingComponent,
});

const Battles = Loadable({
  loader: () => import('@containers/Battles'),
  loading: LoadingComponent,
});

const DeleteQuestionList = Loadable({
  loader: () => import('@containers/DeleteQuestionList'),
  loading: LoadingComponent,
});

const DeleteTournamentList = Loadable({
  loader: () => import('@containers/DeleteTournaments'),
  loading: LoadingComponent,
});

const TournamentMaster = Loadable({
  loader: () => import('@containers/TournamentMaster'),
  loading: LoadingComponent,
});

const Activity = Loadable({
  loader: () => import('@containers/Activity'),
  loading: LoadingComponent,
});

const UserKyc = Loadable({
  loader: () => import('@containers/UserKyc'),
  loading: LoadingComponent,
});

const BlockUser = Loadable({
  loader: () => import('@containers/BlockUser'),
  loading: LoadingComponent,
});

const TermsOfUse = Loadable({
  loader: () => import('@components/TermsOfUse'),
  loading: LoadingComponent,
});

const PrivacyPolicy = Loadable({
  loader: () => import('@components/PrivacyPolicy'),
  loading: LoadingComponent,
});

const PaymentTerms = Loadable({
  loader: () => import('@components/PaymentTerms'),
  loading: LoadingComponent,
});

const RefundAndCancellation = Loadable({
  loader: () => import('@components/RefundAndCancellation'),
  loading: LoadingComponent,
});

const Disclaimer = Loadable({
  loader: () => import('@components/Disclaimer'),
  loading: LoadingComponent,
});

const Test = Loadable({
  loader: () => import('@components/Test'),
  loading: LoadingComponent,
});

const Error = Loadable({
  loader: () => import('@components/Error'),
  loading: LoadingComponent,
});


export default (
  <Router>
    <Fragment>
      <App>
        <OnRouteChange>
          <div className="">
            <Switch>
              <Route exact path={`${APP_CONFIG.BASE_URL}/`} component={Home} />
              <Route exact path={`${APP_CONFIG.BASE_URL}/login`} component={Login} />
              <Route exact path={`${APP_CONFIG.BASE_URL}/users/signup`} component={Register} />
              {/* <Route exact path={`${APP_CONFIG.BASE_URL}/users/demodashboard`} component={DemoDashboard} /> */}
              <Route exact path={`${APP_CONFIG.BASE_URL}/logout`} component={Logout} />
              <Route exact path={`${APP_CONFIG.BASE_URL}/Default`} component={Default} />
              {/* <PrivateRoute key="dashboard" exact path={`${APP_CONFIG.BASE_URL}/Dashboard`} component={Dashboard} /> */}
              <PrivateRoute key="demodashboard" exact path={`${APP_CONFIG.BASE_URL}/Dashboard`} component={DemoDashboard} />
              <PrivateRoute key="payemtProcessing" exact path={`${APP_CONFIG.BASE_URL}/payment_processing`} component={PaymentProcessing} />
              <PrivateRoute key="payemtPage" exact path={`${APP_CONFIG.BASE_URL}/payment_page`} component={PayemtPage} />
              <PrivateRoute key="transactionDetails" exact path={`${APP_CONFIG.BASE_URL}/transaction_page`} component={TransactionDetails} />
              <PrivateRoute key="beneficiarydetails" exact path={`${APP_CONFIG.BASE_URL}/add_beneficiary`} component={AddBeneficiary} />
              <PrivateRoute key="tutorials" exact path={`${APP_CONFIG.BASE_URL}/tutorial`} component={Tutorial} />
              <PrivateRoute key="addTutorials" exact path={`${APP_CONFIG.BASE_URL}/add_tutorial`} component={AddTutorial} />
              <PrivateRoute key="manageCourse" exact path={`${APP_CONFIG.BASE_URL}/manage_course`} component={ManageCourse} />
              <PrivateRoute key="contentUpload" exact path={`${APP_CONFIG.BASE_URL}/upload/content`} component={UploadContent} />
              <PrivateRoute key="tournaments" exact path={`${APP_CONFIG.BASE_URL}/tournaments`} component={Tournaments} />
              <PrivateRoute key="configUpload" exact path={`${APP_CONFIG.BASE_URL}/upload/config`} component={UploadConfig} />
              <PrivateRoute key="questionBank" exact path={`${APP_CONFIG.BASE_URL}/questions`} component={questionList} />
              <PrivateRoute key="viewAs" exact path={`${APP_CONFIG.BASE_URL}/view/as`} component={ViewAs} />
              <PrivateRoute key="battles" exact path={`${APP_CONFIG.BASE_URL}/battles`} component={Battles} />
              <PrivateRoute key="deleteContent" exact path={`${APP_CONFIG.BASE_URL}/delete/questions`} component={DeleteQuestionList} />
              <PrivateRoute key="deleteTournament" exact path={`${APP_CONFIG.BASE_URL}/delete/tournaments`} component={DeleteTournamentList} />
              <PrivateRoute key="tournamentMaster" exact path={`${APP_CONFIG.BASE_URL}/tournamentMaster`} component={TournamentMaster} />
              <PrivateRoute key="activity" exact path={`${APP_CONFIG.BASE_URL}/activity`} component={Activity} />
              <PrivateRoute key="userKyc" exact path={`${APP_CONFIG.BASE_URL}/user/kyc`} component={UserKyc} />
              <PrivateRoute key="blockUSer" exact path={`${APP_CONFIG.BASE_URL}/block/user`} component={BlockUser} />
              <Route path={`${APP_CONFIG.BASE_URL}/TermsOfUse`} component={TermsOfUse} />
              <Route path={`${APP_CONFIG.BASE_URL}/error`} component={Error} />
              <Route path={`${APP_CONFIG.BASE_URL}/PrivacyPolicy`} component={PrivacyPolicy} />
              <Route path={`${APP_CONFIG.BASE_URL}/PaymentTerms`} component={PaymentTerms} />
              <Route path={`${APP_CONFIG.BASE_URL}/refund-cancellation`} component={RefundAndCancellation} />
              <Route path={`${APP_CONFIG.BASE_URL}/Disclaimer`} component={Disclaimer} />
              <Route path={`${APP_CONFIG.BASE_URL}/Test`} component={Test} />
              <Route path="*" component={notFound} />
            </Switch>
          </div>
        </OnRouteChange>
      </App>
      <Footer />
    </Fragment>
  </Router>
);
