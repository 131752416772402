export const GET_USER_HISTORY_REQUESTED = 'admin/GET_USER_HISTORY_REQUESTED';
export const GET_USER_HISTORY_SUCCESS = 'admin/GET_USER_HISTORY_SUCCESS';
export const GET_USER_HISTORY_FAILURE = 'admin/GET_USER_HISTORY_FAILURE';
export const GET_USER_NAME_REQUESTED = 'admin/GET_USER_NAME_REQUESTED';
export const GET_USER_NAME_SUCCESS = 'admin/GET_USER_NAME_SUCCESS';
export const GET_USER_NAME_FAILURE = 'admin/GET_USER_NAME_FAILURE';
export const GET_USERS_BY_DATE_REQUESTED = 'admin/GET_USERS_BY_DATE_REQUESTED';
export const GET_USERS_BY_DATE_SUCCESS = 'admin/GET_USERS_BY_DATE_SUCCESS';
export const GET_USERS_BY_DATE_FAILURE = 'admin/GET_USERS_BY_DATE_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errMessage: '',
  code: 0,
  adminUserHistory: {},
  userProfile: {},
  activityDetails: [],
  transactionHistory: [],
  getAdminUserHistoryLoading: false,
  totalCount: 0,
  totalTransactionCount: 0,
  name: [],
  getUserListLoading: false,
  totalUsersCount: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_HISTORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        getAdminUserHistoryLoading: true,
        // ftueQuestionList: [],
      };
    }
    case GET_USER_HISTORY_SUCCESS: {
      console.log('bbbbbbbbbbbbbbbbbbbbbbbbb', action.result.adminUserHistory.totalCount);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        adminUserHistory: action.result.adminUserHistory,
        userProfile: action.result.adminUserHistory.userProfile,
        activityDetails: action.result.adminUserHistory.activityuserHistory,
        transactionHistory: action.result.adminUserHistory.transactionHistory,
        getAdminUserHistoryLoading: false,
        totalCount: action.result.adminUserHistory.totalCount,
        totalTransactionCount: action.result.adminUserHistory.totalTransactionCount,
        ftueQuestionList: action.result.adminUserHistory.ftueQuestionList,
        walletDetails: action.result.adminUserHistory.walletDetails
      };
    }
    case GET_USER_HISTORY_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        getAdminUserHistoryLoading: false,
      };
    }
    case GET_USER_NAME_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_USER_NAME_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        name: action.result.name,
      };
    }
    case GET_USER_NAME_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    case GET_USERS_BY_DATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        getUserListLoading: true
      };
    }
    case GET_USERS_BY_DATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        userList: action.result.userList,
        totalUsersCount: action.result.totalCount,
        getUserListLoading: false
      };
    }
    case GET_USERS_BY_DATE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        getUserListLoading: false
      };
    }
    default:
      return state;
  }
};


export const getUserHistory = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GET_USER_HISTORY_REQUESTED, GET_USER_HISTORY_SUCCESS, GET_USER_HISTORY_FAILURE],
    promise: client => client.get(`getUserHistory/${data.itemsPerPage}/${data.pageNo}?searchByMobileNo=${data.searchByMobileNo}&searchByEmail=${data.searchByEmail}&searchByName=${data.searchByName}&startDate=${data.startDate}&endDate=${data.endDate}&tournamentSearchKey=${data.tournamentSearchKey}`)
  };
};
export const getName = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GET_USER_NAME_REQUESTED, GET_USER_NAME_SUCCESS, GET_USER_NAME_FAILURE],
    promise: client => client.get(`getName/?searchKey=${data.searchKey}`)
  };
};

export const getUsersByDate = (data) => {
  console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', data);
  return {
    types: [GET_USERS_BY_DATE_REQUESTED, GET_USERS_BY_DATE_SUCCESS, GET_USERS_BY_DATE_FAILURE],
    promise: client => client.get(`getUsersByDate/${data.itemsPerPage}/${data.pageNo}?startDate=${data.startDate}&endDate=${data.endDate}&searchByMobileNo=${data.searchByMobileNo}`)
  };
};
