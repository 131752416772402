import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const GET_QUESTIONS_REQUESTED = 'tournamentUpload/GET_QUESTIONS_REQUESTED';
export const GET_QUESTIONS_SUCCESS = 'tournamentUpload/GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'tournamentUpload/GET_QUESTIONS_FAILURE';

export const GET_APPCONFIG_REQUESTED = 'tournamentUpload/GET_APPCONFIG_REQUESTED';
export const GET_APPCONFIG_SUCCESS = 'tournamentUpload/GET_APPCONFIG_SUCCESS';
export const GET_APPCONFIG_FAILURE = 'tournamentUpload/GET_APPCONFIG_FAILURE';

export const GET_FILTERS_REQUESTED = 'tournamentUpload/GET_FILTERS_REQUESTED';
export const GET_FILTERS_SUCCESS = 'tournamentUpload/GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILURE = 'tournamentUpload/GET_FILTERS_FAILURE';

export const UPDATE_QUESTIONS_REQUESTED = 'tournamentUpload/UPDATE_QUESTIONS_REQUESTED';
export const UPDATE_QUESTIONS_SUCCESS = 'tournamentUpload/UPDATE_QUESTIONS_SUCCESS';
export const UPDATE_QUESTIONS_FAILURE = 'tournamentUpload/UPDATE_QUESTIONS_FAILURE';

export const UPLOAD_QUESTIONS_REQUESTED = 'tournamentUpload/UPLOAD_QUESTIONS_REQUESTED';
export const UPLOAD_QUESTIONS_SUCCESS = 'tournamentUpload/UPLOAD_QUESTIONS_SUCCESS';
export const UPLOAD_QUESTIONS_FAILURE = 'tournamentUpload/UPLOAD_QUESTIONS_FAILURE';
export const DELETE_QUESTIONS_REQUESTED = 'tournamentUpload/DELETE_QUESTIONS_REQUESTED';
export const DELETE_QUESTIONS_SUCCESS = 'tournamentUpload/DELETE_QUESTIONS_SUCCESS';
export const DELETE_QUESTIONS_FAILURE = 'tournamentUpload/DELETE_QUESTIONS_FAILURE';

export const IS_QUESTION_EXISTS_REQUESTED = 'tournamentUpload/IS_QUESTION_EXISTS_REQUESTED';
export const IS_QUESTION_EXISTS_SUCCESS = 'tournamentUpload/IS_QUESTION_EXISTS_SUCCESS';
export const IS_QUESTION_EXISTS_FAILURE = 'tournamentUpload/IS_QUESTION_EXISTS_FAILURE';

export const ENABLE_QUESTIONS_REQUESTED = 'tournamentUpload/ENABLE_QUESTIONS_REQUESTED';
export const ENABLE_QUESTIONS_SUCCESS = 'tournamentUpload/ENABLE_QUESTIONS_SUCCESS';
export const ENABLE_QUESTIONS_FAILURE = 'tournamentUpload/ENABLE_QUESTIONS_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  questionsDetails: [],
  pathList: [],
  questionExists: [],
  questionExistsloading: false,
  questionExistsloaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        questionsDetails: action.result.questions,
        totalCount: action.result.totalRecords
      };
    }
    case GET_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_APPCONFIG_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_APPCONFIG_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        serverImgUrl: action.result && action.result.appconfigDTO && action.result.appconfigDTO.awsParams && action.result.appconfigDTO.awsParams.imageServerUrl
      };
    }
    case GET_APPCONFIG_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_FILTERS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_FILTERS_SUCCESS:
    {
      console.log('action.result', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        filtersDetails: action.result
      };
    }
    case GET_FILTERS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DELETE_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPDATE_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPDATE_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case IS_QUESTION_EXISTS_REQUESTED:
    {
      return {
        ...state,
        questionExistsloading: true,
        questionExistsloaded: false,
      };
    }
    case IS_QUESTION_EXISTS_SUCCESS:
    {
      return {
        ...state,
        questionExistsloading: false,
        error: false,
        questionExistsloaded: true,
        questionExists: action.result
      };
    }
    case IS_QUESTION_EXISTS_FAILURE:
    {
      return {
        ...state,
        questionExistsloading: false,
        questionExistsloaded: false,
        error: true,
      };
    }
    case ENABLE_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ENABLE_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case ENABLE_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPLOAD_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPLOAD_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case UPLOAD_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getQuestions = (data, itemsPerPage, pageNo) => {
  return {
    types: [GET_QUESTIONS_REQUESTED, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    promise: client => client.post(`getQuestionBank/${itemsPerPage}/${pageNo}`, {
      data
    })
  };
};

export const getAppConfigDetails = () => {
  return {
    types: [GET_APPCONFIG_REQUESTED, GET_APPCONFIG_SUCCESS, GET_APPCONFIG_FAILURE],
    promise: client => client.get('getAppConfigDetail?language=english&clientId=ccAbgFftuznTHa-WLG7Fng', {
    })
  };
};

export const getQuestionsFilters = () => {
  return {
    types: [GET_FILTERS_REQUESTED, GET_FILTERS_SUCCESS, GET_FILTERS_FAILURE],
    promise: client => client.get('getQuestionsFilters', {
    })
  };
};

export const getQuestionDetailsById = (data) => {
  return {
    types: [GET_QUESTIONS_REQUESTED, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    promise: client => client.post('getQuestion', {
      data
    })
  };
};

export const deleteQuestions = (data) => {
  return {
    types: [DELETE_QUESTIONS_REQUESTED, DELETE_QUESTIONS_SUCCESS, DELETE_QUESTIONS_FAILURE],
    promise: client => client.post('deleteQuestionBank', {
      data
    })
  };
};

export const editQuestion = (data) => {
  return {
    types: [UPDATE_QUESTIONS_REQUESTED, UPDATE_QUESTIONS_SUCCESS, UPDATE_QUESTIONS_FAILURE],
    promise: client => client.post('editQuestion', {
      data
    })
  };
};

export const isQuestionsExists = (data) => {
  return {
    types: [IS_QUESTION_EXISTS_REQUESTED, IS_QUESTION_EXISTS_SUCCESS, IS_QUESTION_EXISTS_FAILURE],
    promise: client => client.post('isQuestionsExists', {
      data
    })
  };
};

export const uploadQuestion = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [UPLOAD_QUESTIONS_REQUESTED, UPLOAD_QUESTIONS_SUCCESS, UPLOAD_QUESTIONS_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_CONTENT_HOST_NAME}/questionbank/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('files', file)
  };
};

export const enableQuestion = (data) => {
  return {
    types: [DELETE_QUESTIONS_REQUESTED, DELETE_QUESTIONS_SUCCESS, DELETE_QUESTIONS_FAILURE],
    promise: client => client.post('enableQuestion', {
      data
    })
  };
};
