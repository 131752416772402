export default {

  endpoints: {
    loginapi: '/api/auth/authenticate',
    logoutapi: '/userservice/account/logout',
    dashboardApi: '/api/users/dashboard',
    verifySponserCall: '/api/users/find',
    paymentOrderDetails: '/api/payment/getPayInOrder',
    paymentTransactions: '/api/payment/transactions',
    submitPaymentForm: '/api/payment/payout/payout_account',
    withdraw: '/api/payment/payout/withdraw',
    register: '/api/auth/register',
    getTutorial: '/api/tutorial/video-tutorial',
    getVideos: '/api/tutorial/video-tutorial/videos',
    addTutorial: '/api/tutorial/add-tutorial',
    viewOrgCourses:'/api/tutorial/courses-org',
    viewOrgVideos:'/api/tutorial/videos-org',
    createCourse:'/api/tutorial/add-course',
    createVideo:'/api/tutorial/add-video',
    forgotPassword: '/account/resetpwd',
    fileUpload: '/content/questionbank/upload/file/',
    saveQuestionBank: '/content/questionbank/add',
    tournamentUpload: '/tour/tournament/upload',
    tournamentSave: '/tour/tournament/saveExcel',
    saveFolder: '/content/questionbank/upload/folder',
    getTournamentDetails: '/tour/tournament/get/list/admin',
    appConfigUpload: '/appconfig/upload',
    appConfigSave: '/appconfig/add',
    homeConfigSave: '/homeConfig/save/excel',
    getTransactionDetails: '/transaction/DashboardDetails/get',
    getQuestionDetails: '/content/challenge/get/challenge/questions/byquestionids',
    updateTournament: '/tour/tournament/update',
    userConfigSave: '/userconfig/save/excel',
    getQuestionBank: '/content/questionbank/get/list',
    getAppConfigDetail: '/userservice/appconfig/get/appconfig',
    getQuestionsFilters: '/content/questionbank/get/filter',
    referralConfigSave: '/referralconfig/save/excel',
    ftueConfigSave: '/ftue/save/excel',
    dailyConfigSave: '/gamification/save/daily/excel',
    spinConfigSave: '/gamification/save/spin/excel',
    syncWithGcp: '/content/gcp/file',
    battleSave: '/battle/excel/upload',
    getBattleDetails: '/tour/battle/get/battle/counts',
    getBattlesList: '/tour/battle/get/battlelist/admin',
    getUserHistory: '/content/admin/get/user/history',
    getName: '/userservice/search/get/name',
    botConfigSave: '/battle/save/bot',
    botUserSave: '/userservice/bots/save/bots/excel',
    getAppconfig: '/userservice/appconfig/get/appconfig',
    getbotUser: '/userservice/bots/get/botuser',
    getuserConfig: '/userservice/userconfig/get/userconfig',
    getuserReferral: '/userservice/referralconfig/get/referral',
    getFtueDetails: '/tour/ftue/get/ftue',
    getDailyDetails: '/activity/gamification/get/daily/excel',
    botConfigList: '/tour/battle/config/list',
    getLeaderboardDetails: '/tour/tournament/admin/get/leaderboard',
    getAppConfig: '/userservice/appconfig/get/appconfig',
    getHomeConfig: '/tour/homeConfig/get/homepage/config',
    getSpinConfig: '/activity/gamification/get/spin/excel',
    getAvatarConfig: '/userservice/avatar/get/avatar',
    saveScratchConfig: '/activity/gamification/save/scratch/excel',
    getScratchCongfig: '/activity/gamification/get/scratch/card',
    getslotmachine: '/activity/gamification/get/slot/machine',
    saveSlotMachineConfig: '/activity/gamification/save/slot/machine',
    getTournamentMaster: '/tour/tournament/get/master/data',
    getQuestion: '/content/get/questions/byquestionid',
    editQuestion: '/content/questionbank/update/questionbank',
    uploadQuestion: '/content/questionbank/upload',
    deleteQuestionBank: '/content/questionbank/delete',
    deleteTournament: '/tour/tournament/delete/tournament',
    isQuestionsExists: '/tour/tournament/isquestionsexists',
    enableQuestion: '/content/questionbank/update/questions/status',
    getUsersByDate: '/userservice/onmobile/get/users/by/date',
    getUserKycList: '/userservice/user/get/list/user/kyc',
    updateUserKyc: '/userservice/user/update/kyc',
    getAllUsers: '/userservice/onmobile/get/all/users',
    updateUser: '/userservice/onmobile/update/user',
    getPendingKycCount: '/userservice/user/get/pending/kyc/count'
  },
};
