export const TOURNAMENT_DETAILS_FOR_ADMIN_REQUESTED = 'dashboard/TOURNAMENT_DETAILS_FOR_ADMIN_REQUESTED';
export const TOURNAMENT_DETAILS_FOR_ADMIN_SUCCESS = 'dashboard/TOURNAMENT_DETAILS_FOR_ADMIN_SUCCESS';
export const TOURNAMENT_DETAILS_FOR_ADMIN_FAILURE = 'dashboard/TOURNAMENT_DETAILS_FOR_ADMIN_FAILURE';

export const TRANSACTION_DETAILS_FOR_ADMIN_REQUESTED = 'dashboard/TRANSACTION_DETAILS_FOR_ADMIN_REQUESTED';
export const TRANSACTION_DETAILS_FOR_ADMIN_SUCCESS = 'dashboard/TRANSACTION_DETAILS_FOR_ADMIN_SUCCESS';
export const TRANSACTION_DETAILS_FOR_ADMIN_FAILURE = 'dashboard/TRANSACTION_DETAILS_FOR_ADMIN_FAILURE';

export const BATTLES_DETAILS_FOR_ADMIN_REQUESTED = 'dashboard/BATTLES_DETAILS_FOR_ADMIN_REQUESTED';
export const BATTLES_DETAILS_FOR_ADMIN_SUCCESS = 'dashboard/BATTLES_DETAILS_FOR_ADMIN_SUCCESS';
export const BATTLES_DETAILS_FOR_ADMIN_FAILURE = 'dashboard/BATTLES_DETAILS_FOR_ADMIN_FAILURE';

export const GET_PENDING_KYC_COUNT_REQUESTED = 'dashboard/GET_PENDING_KYC_COUNT_REQUESTED';
export const GET_PENDING_KYC_COUNT_SUCCESS = 'dashboard/GET_PENDING_KYC_COUNT_SUCCESS';
export const GET_PENDING_KYC_COUNT_FAILURE = 'dashboard/GET_PENDING_KYC_COUNT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errMessage: '',
  code: 0,
  tournamentList: [],
  totalTournament: 0,
  liveTournament: 0,
  draftTournament: 0,
  instanceCreated: 0,
  completedTournament: 0,
  invalidatedTournament: 0,
  totalCount: 0,
  getTournamentLoading: false,
  transactionData: [],
  upcomingFlag: false,
  liveFlag: false,
  dashBoradDetails: [],
  totalBattle: 0,
  liveBattles: 0,
  completedBattles: 0,
  availableBattles: 0,
  getBattlesLoading: false,
  pendingKycCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_DETAILS_FOR_ADMIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        getTournamentLoading: true,
      };
    }
    case TOURNAMENT_DETAILS_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        tournamentList: action.result.tournamentDetailsList.tournamentDetails,
        totalTournament: action.result.tournamentDetailsList.totalTournament,
        draftTournament: action.result.tournamentDetailsList.draftTournament,
        instanceCreated: action.result.tournamentDetailsList.instanceCreated,
        liveTournament: action.result.tournamentDetailsList.liveTournament,
        totalCount: action.result.tournamentDetailsList.total,
        completedTournament: action.result.tournamentDetailsList.completedTournament,
        invalidatedTournament: action.result.tournamentDetailsList.invalidatedTournament,
        getTournamentLoading: false,
        upcomingFlag: action.result.tournamentDetailsList.upcomingFlag,
        liveFlag: action.result.tournamentDetailsList.liveFlag
      };
    }
    case TOURNAMENT_DETAILS_FOR_ADMIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        getTournamentLoading: false,
      };
    }
    case TRANSACTION_DETAILS_FOR_ADMIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case TRANSACTION_DETAILS_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        transactionData: action.result,
      };
    }
    case TRANSACTION_DETAILS_FOR_ADMIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    case BATTLES_DETAILS_FOR_ADMIN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        getBattlesLoading: true,
      };
    }
    case BATTLES_DETAILS_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        liveBattles: action.result.dashBoardCount.liveBattles,
        completedBattles: action.result.dashBoardCount.completedBattles,
        availableBattles: action.result.dashBoardCount.availableBattles,
        totalBattle: action.result.dashBoardCount.totalBattle,
      };
    }
    case BATTLES_DETAILS_FOR_ADMIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        getBattlesLoading: false,
      };
    }
    case GET_PENDING_KYC_COUNT_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case GET_PENDING_KYC_COUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        pendingKycCount: action.result.pendingKycCount,
      };
    }
    case GET_PENDING_KYC_COUNT_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
      };
    }
    default:
      return state;
  }
};


export const getTournamentDetails = (data) => {
  return {
    types: [TOURNAMENT_DETAILS_FOR_ADMIN_REQUESTED, TOURNAMENT_DETAILS_FOR_ADMIN_SUCCESS, TOURNAMENT_DETAILS_FOR_ADMIN_FAILURE],
    promise: client => client.get(`getTournamentDetails/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&filterStatus=${data.filterStatus}&startDate=${data.startDate}&endDate=${data.endDate}&daily=${data.daily}&rewardType=${data.rewardType}`)
  };
};

export const getTransactionDetails = (data) => {
  return {
    types: [TRANSACTION_DETAILS_FOR_ADMIN_REQUESTED, TRANSACTION_DETAILS_FOR_ADMIN_SUCCESS, TRANSACTION_DETAILS_FOR_ADMIN_FAILURE],
    promise: client => client.get(`getTransactionDetails?daily=${data.daily}`)
  };
};

export const getBattleDetails = (data) => {
  return {
    types: [BATTLES_DETAILS_FOR_ADMIN_REQUESTED, BATTLES_DETAILS_FOR_ADMIN_SUCCESS, BATTLES_DETAILS_FOR_ADMIN_FAILURE],
    promise: client => client.get(`getBattleDetails?daily=${data.daily}`)
  };
};

export const getPendingKycCount = () => {
  return {
    types: [GET_PENDING_KYC_COUNT_REQUESTED, GET_PENDING_KYC_COUNT_SUCCESS, GET_PENDING_KYC_COUNT_FAILURE],
    promise: client => client.get('getPendingKycCount')
  };
};
