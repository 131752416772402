export const LOGIN_REQUESTED = 'login/LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'login/LOGIN_FAILURE';

export const REGISTER_REQUESTED = 'login/REGISTER_REQUESTED';
export const REGISTER_SUCCESS = 'login/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'login/REGISTER_FAILURE';

export const GET_DASHBOARD_REQUESTED = 'login/GET_DASHBOARD_REQUESTED';
export const GET_DASHBOARD_SUCCESS = 'login/GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'login/GET_DASHBOARD_FAILURE';

export const VERIFY_SPONSER_REQUESTED = 'login/VERIFY_SPONSER_REQUESTED';
export const VERIFY_SPONSER_SUCCESS = 'login/VERIFY_SPONSER_SUCCESS';
export const VERIFY_SPONSER_FAILURE = 'login/VERIFY_SPONSER_FAILURE';

export const WITHDRAW_REQUESTED = 'login/WITHDRAW_REQUESTED';
export const WITHDRAW_SUCCESS = 'login/WITHDRAW_SUCCESS';
export const WITHDRAW_FAILURE = 'login/WITHDRAW_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  data: [],
  code: '',
  msg: '',
  status: '',
  dashboardData: {},
  registerationFailedMsg: '',
  sponserVerifiedData: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case LOGIN_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action && action.result && action.result.access_token,
        code: '',
        msg: 'Logged in successfuly',
        status: '200'
      };
    }
    case LOGIN_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.result.code,
        msg: action && action.result && action.result.msg,
        status: action && action.result && action.result.status
      };
    }
    case REGISTER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REGISTER_SUCCESS:
    {
      console.log('successsss', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action && action.result,
        code: '',
        msg: 'Logged in successfuly',
        status: '200'
      };
    }
    case REGISTER_FAILURE:
    {
      console.log('failedddddddd', action.error.response.data);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.result.code,
        msg: action && action.result && action.result.msg,
        registerationFailedMsg: action && action.error && action.error.response && action.error.response.data.message,
        status: action && action.result && action.result.status
      };
    }
    case GET_DASHBOARD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_DASHBOARD_SUCCESS:
    {
      console.log('dddddddddddddddddddddddddddddd', action.result.tree);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action && action.result && action.result.access_token,
        dashboardData: action && action.result,
        code: '',
        msg: 'Logged in successfuly',
        status: '200'
      };
    }
    case GET_DASHBOARD_FAILURE:
    {
      console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq', action);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        // code: action && action.result && action.result.code,
        // msg: action && action.result && action.result.msg,
        // status: action && action.result && action.result.status
      };
    }
    case VERIFY_SPONSER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case VERIFY_SPONSER_SUCCESS:
    {
      console.log('dddddddddddddddddddddddddddddd', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        code: '',
        sponserVerifiedData: action && action.result,
        msg: 'Logged in successfuly',
        registerationFailedMsg: '',
        status: '200'
      };
    }
    case VERIFY_SPONSER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.result.code,
        msg: action && action.result && action.result.msg,
        registerationFailedMsg: action && action.error && action.error.response && action.error.response.data,
        status: action && action.result && action.result.status
      };
    }
    case WITHDRAW_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case WITHDRAW_SUCCESS:
    {
      console.log('successsss', action.result);
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data:   action.result.response.data,
        status: '200'
      };
    }
    case WITHDRAW_FAILURE:
    {
      console.log('failedddddddd',action.error);
      return {
        ...state,
        loading: false,
        loaded: false,
        data:  action.error,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const loginCall = (data) => {
  return {
    types: [LOGIN_REQUESTED, LOGIN_SUCCESS, LOGIN_FAILURE],
    promise: client => client.post('loginapi', {
      data
    })
  };
};

export const registerapCall = (data) => {
  console.log('333333333333333333333', data);
  return {
    types: [REGISTER_REQUESTED, REGISTER_SUCCESS, REGISTER_FAILURE],
    promise: client => client.post('register', {
      data
    })
  };
};

export const dashboardApi = () => {
  return {
    types: [GET_DASHBOARD_REQUESTED, GET_DASHBOARD_SUCCESS, GET_DASHBOARD_FAILURE],
    promise: client => client.get('dashboardApi', {
    })
  };
};

export const verifySponserCall = (sponserId) => {
  console.log('999999999999999993333333333333', sponserId);
  return {
    types: [VERIFY_SPONSER_REQUESTED, VERIFY_SPONSER_SUCCESS, VERIFY_SPONSER_FAILURE],
    promise: client => client.get(`verifySponserCall/${sponserId}`, {
    })
  };
};

export const withdrawCall = (data) => {
  console.log('333333333333333333333', data);
  return {
    types: [WITHDRAW_REQUESTED, WITHDRAW_SUCCESS, WITHDRAW_FAILURE],
    promise: client => client.post('withdraw', {
       data
    })
  };
};
