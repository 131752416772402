import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const QB_UPLOAD_REQUESTED = 'contentupload/QB_UPLOAD_REQUESTED';
export const QB_UPLOAD_SUCCESS = 'contentupload/QB_UPLOAD_SUCCESS';
export const QB_UPLOAD_FAILURE = 'contentupload/QB_UPLOAD_FAILURE';

export const QB_UPLOAD_GCS_REQUESTED = 'contentupload/QB_UPLOAD_GCS_REQUESTED';
export const QB_UPLOAD_GCS_SUCCESS = 'contentupload/QB_UPLOAD_GCS_SUCCESS';
export const QB_UPLOAD_GCS_FAILURE = 'contentupload/QB_UPLOAD_GCS_FAILURE';

export const QB_SAVE_REQUESTED = 'contentupload/QB_SAVE_REQUESTED';
export const QB_SAVE_SUCCESS = 'contentupload/QB_SAVE_SUCCESS';
export const QB_SAVE_FAILURE = 'contentupload/QB_SAVE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  loadingUpload: false,
  loadedUpload: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case QB_UPLOAD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case QB_UPLOAD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case QB_UPLOAD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case QB_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case QB_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case QB_SAVE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case QB_UPLOAD_GCS_REQUESTED:
    {
      return {
        ...state,
        loadingUpload: true,
        loadedUpload: false,
      };
    }
    case QB_UPLOAD_GCS_SUCCESS:
    {
      return {
        ...state,
        loadingUpload: false,
        error: false,
        loadedUpload: true,
      };
    }
    case QB_UPLOAD_GCS_FAILURE:
    {
      return {
        ...state,
        loadingUpload: false,
        loadedUpload: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const uploadFile = (file) => {
  const data = new FormData();
  data.append('file', file);
  return {
    types: [QB_UPLOAD_REQUESTED, QB_UPLOAD_SUCCESS, QB_UPLOAD_FAILURE],
    promise: client => client.post('fileUpload', {
      data
    })
  };
};

// export const saveQuestionBank = (data) => {
//   return {
//     types: [QB_UPLOAD_REQUESTED, QB_UPLOAD_SUCCESS, QB_UPLOAD_FAILURE],
//     promise: client => client.post('saveQuestionBank', {
//       data
//     })
//   };
// };

export const saveQuestionBank = (data, file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [QB_UPLOAD_REQUESTED, QB_UPLOAD_SUCCESS, QB_UPLOAD_FAILURE],
    promise: () => upload
    // .post('http://localhost:8084/RMFConsumer/api/upload/timesheet/docs')
      .post(`${APP_CONFIG.CURRENT_CONTENT_HOST_NAME}/questionbank/add`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
      .field('fileInfo', JSON.stringify(data)),
  };
};

export const saveFolder = (file) => {
  const data = new FormData();
  data.append('folder', file);
  return {
    types: [QB_UPLOAD_GCS_REQUESTED, QB_UPLOAD_GCS_SUCCESS, QB_UPLOAD_GCS_FAILURE],
    promise: client => client.post('saveFolder', {
      data
    })
  };
};

export const syncWithGcp = () => {
  return {
    types: [QB_UPLOAD_REQUESTED, QB_UPLOAD_SUCCESS, QB_UPLOAD_FAILURE],
    promise: client => client.post('syncWithGcp', {
    })
  };
};
