export const GET_LEADERBOARD_REQUESTED = 'leaderboard/GET_LEADERBOARD_REQUESTED';
export const GET_LEADERBOARD_SUCCESS = 'leaderboard/GET_LEADERBOARD_SUCCESS';
export const GET_LEADERBOARD_FAILURE = 'leaderboard/GET_LEADERBOARD_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  leaderBoardList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LEADERBOARD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_LEADERBOARD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        leaderBoardList: action.result.leaderBoardList,
      };
    }
    case GET_LEADERBOARD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getLeaderboardDetails = (tournamentId) => {
  return {
    types: [GET_LEADERBOARD_REQUESTED, GET_LEADERBOARD_SUCCESS, GET_LEADERBOARD_FAILURE],
    promise: client => client.get(`getLeaderboardDetails?tournamentId=${tournamentId}`)
  };
};
