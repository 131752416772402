import { APP_CONFIG } from '@constants';
import axios from 'axios';

const upload = require('superagent');


export const APPCONFIG_UPLOAD_REQUESTED = 'appconfigUpload/APPCONFIG_UPLOAD_REQUESTED';
export const APPCONFIG_UPLOAD_SUCCESS = 'appconfigUpload/APPCONFIG_UPLOAD_SUCCESS';
export const APPCONFIG_UPLOAD_FAILURE = 'appconfigUpload/APPCONFIG_UPLOAD_FAILURE';

export const APPCONFIG_SAVE_REQUESTED = 'appconfigUpload/APPCONFIG_SAVE_REQUESTED';
export const APPCONFIG_SAVE_SUCCESS = 'appconfigUpload/APPCONFIG_SAVE_SUCCESS';
export const APPCONFIG_SAVE_FAILURE = 'appconfigUpload/APPCONFIG_SAVE_FAILURE';

export const HOMECONFIG_SAVE_REQUESTED = 'homeconfigUpload/HOMECONFIG_SAVE_REQUESTED';
export const HOMECONFIG_SAVE_SUCCESS = 'homeconfigUpload/HOMECONFIG_SAVE_SUCCESS';
export const HOMECONFIG_SAVE_FAILURE = 'homeconfigUpload/HOMECONFIG_SAVE_FAILURE';

export const USERCONFIG_SAVE_REQUESTED = 'userconfigUpload/USERCONFIG_SAVE_REQUESTED';
export const USERCONFIG_SAVE_SUCCESS = 'userconfigUpload/USERCONFIG_SAVE_SUCCESS';
export const USERCONFIG_SAVE_FAILURE = 'userconfigUpload/USERCONFIG_SAVE_FAILURE';

export const REFERRALCONFIG_SAVE_REQUESTED = 'referralconfigUpload/REFERRALCONFIG_SAVE_REQUESTED';
export const REFERRALCONFIG_SAVE_SUCCESS = 'referralconfigUpload/REFERRALCONFIG_SAVE_SUCCESS';
export const REFERRALCONFIG_SAVE_FAILURE = 'referralconfigUpload/REFERRALCONFIG_SAVE_FAILURE';

export const FTUECONFIG_SAVE_REQUESTED = 'ftueconfigUpload/FTUECONFIG_SAVE_REQUESTED';
export const FTUECONFIG_SAVE_SUCCESS = 'ftueconfigUpload/FTUECONFIG_SAVE_SUCCESS';
export const FTUECONFIG_SAVE_FAILURE = 'ftueconfigUpload/FTUECONFIG_SAVE_FAILURE';

export const DAILYCONFIG_SAVE_REQUESTED = 'dailyconfigUpload/DAILYCONFIG_SAVE_REQUESTED';
export const DAILYCONFIG_SAVE_SUCCESS = 'dailyconfigUpload/DAILYCONFIG_SAVE_SUCCESS';
export const DAILYCONFIG_SAVE_FAILURE = 'dailyconfigUpload/DAILYCONFIG_SAVE_FAILURE';

export const SPINCONFIG_SAVE_REQUESTED = 'spinconfigUpload/SPINCONFIG_SAVE_REQUESTED';
export const SPINCONFIG_SAVE_SUCCESS = 'spinconfigUpload/SPINCONFIG_SAVE_SUCCESS';
export const SPINCONFIG_SAVE_FAILURE = 'spinconfigUpload/SPINCONFIG_SAVE_FAILURE';

export const BOTCONFIG_SAVE_REQUESTED = 'botconfigUpload/BOTCONFIG_SAVE_REQUESTED';
export const BOTCONFIG_SAVE_SUCCESS = 'botconfigUpload/BOTCONFIG_SAVE_SUCCESS';
export const BOTCONFIG_SAVE_FAILURE = 'botconfigUpload/BOTCONFIG_SAVE_FAILURE';

export const BOTUSER_SAVE_REQUESTED = 'botuserUpload/BOTUSER_SAVE_REQUESTED';
export const BOTUSER_SAVE_SUCCESS = 'botuserUpload/BOTUSER_SAVE_SUCCESS';
export const BOTUSER_SAVE_FAILURE = 'botuserUpload/BOTUSER_SAVE_FAILURE';

export const GETAPPCONFIG_SAVE_REQUESTED = 'appconfig/GETAPPCONFIG_SAVE_REQUESTED';
export const GETAPPCONFIG_SAVE_SUCCESS = 'appconfig/GETAPPCONFIG_SAVE_SUCCESS';
export const GETAPPCONFIG_SAVE_FAILURE = 'appconfig/GETAPPCONFIG_SAVE_FAILURE';

export const GET_HOMECONFIG_REQUESTED = 'homeconfig/GET_HOMECONFIG_REQUESTED';
export const GET_HOMECONFIG_SUCCESS = 'homeconfig/GET_HOMECONFIG_SUCCESS';
export const GET_HOMECONFIG_FAILURE = 'homeconfig/GET_HOMECONFIG_FAILURE';

export const GET_SPINCONFIG_REQUESTED = 'spinconfig/GET_SPINCONFIG_REQUESTED';
export const GET_SPINCONFIG_SUCCESS = 'spinconfig/GET_SPINCONFIG_SUCCESS';
export const GET_SPINCONFIG_FAILURE = 'spinconfig/GET_SPINCONFIG_FAILURE';

export const GET_BOT_CONFIG_REQUESTED = 'battles/GET_BOT_CONFIG_REQUESTED';
export const GET_BOT_CONFIG_SUCCESS = 'battles/GET_BOT_CONFIG_SUCCESS';
export const GET_BOT_CONFIG_FAILURE = 'battles/GET_BOT_CONFIG_FAILURE';

export const GET_AVATAR_CONFIG_REQUESTED = 'avatar/GET_AVATAR_CONFIG_REQUESTED';
export const GET_AVATAR_CONFIG_SUCCESS = 'avatar/GET_AVATAR_CONFIG_SUCCESS';
export const GET_AVATAR_CONFIG_FAILURE = 'avatar/GET_AVATAR_CONFIG_FAILURE';

export const GET_SCRATCH_CARD_REQUESTED = 'scratch/GET_SCRATCH_CARD_REQUESTED';
export const GET_SCRATCH_CARD_SUCCESS = 'scratch/GET_SCRATCH_CARD_SUCCESS';
export const GET_SCRATCH_CONFIG_FAILURE = 'scratch/GET_SCRATCH_CONFIG_FAILURE';

export const GET_SLOTMACHINE_ONE_REQUESTED = 'slot/GET_SLOTMACHINE_ONE_REQUESTED';
export const GET_SLOTMACHINE_ONE_SUCCESS = 'slot/GET_SLOTMACHINE_ONE_SUCCESS';
export const GET_SLOTMACHINE_ONE_FAILURE = 'slot/GET_SLOTMACHINE_ONE_FAILURE';

export const SCRATCHCONFIG_SAVE_REQUESTED = 'scratchconfigUpload/SCRATCHCONFIG_SAVE_REQUESTED';
export const SCRATCHCONFIG_SAVE_SUCCESS = 'scratchconfigUpload/SCRATCHCONFIG_SAVE_SUCCESS';
export const SCRATCHCONFIG_SAVE_FAILURE = 'scratchconfigUpload/SCRATCHCONFIG_SAVE_FAILURE';

export const SLOTCONFIG_SAVE_REQUESTED = 'slotconfigUpload/SLOTCONFIG_SAVE_REQUESTED';
export const SLOTCONFIG_SAVE_SUCCESS = 'sslotchconfigUpload/SLOTCONFIG_SAVE_SUCCESS';
export const SLOTCONFIG_SAVE_FAILURE = 'slotconfigUpload/SLOTCONFIG_SAVE_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  getloading: false,
  getloaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  app: null,
  home: null,
  spin: null,
  bot: [],
  avatar: null,
  card: [],
  slot: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APPCONFIG_UPLOAD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case APPCONFIG_UPLOAD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case APPCONFIG_UPLOAD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case APPCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case APPCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case APPCONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case HOMECONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case HOMECONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case HOMECONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case USERCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case USERCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case USERCONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case REFERRALCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REFERRALCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case REFERRALCONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case FTUECONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case FTUECONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case FTUECONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DAILYCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DAILYCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case DAILYCONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SPINCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SPINCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SPINCONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case BOTCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BOTCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case BOTCONFIG_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GETAPPCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        getloading: true,
        getloaded: false,
      };
    }
    case GETAPPCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        getloading: false,
        error: false,
        getloaded: true,
        app: action.result
      };
    }
    case GETAPPCONFIG_SAVE_FAILURE:
    {
      return {
        ...state,
        getloading: false,
        getloaded: false,
        error: true,
      };
    }
    case GET_HOMECONFIG_REQUESTED:
    {
      return {
        ...state,
        getloading: true,
        getloaded: false,
      };
    }
    case GET_HOMECONFIG_SUCCESS:
    {
      return {
        ...state,
        getloading: false,
        error: false,
        getloaded: true,
        home: action.result
      };
    }
    case GET_HOMECONFIG_FAILURE:
    {
      return {
        ...state,
        getloading: false,
        getloaded: false,
        error: true,
      };
    }
    case GET_SPINCONFIG_REQUESTED:
    {
      return {
        ...state,
        getloading: true,
        getloaded: false,
      };
    }
    case GET_SPINCONFIG_SUCCESS:
    {
      return {
        ...state,
        getloading: false,
        error: false,
        getloaded: true,
        spin: action.result
      };
    }
    case GET_SPINCONFIG_FAILURE:
    {
      return {
        ...state,
        getloading: false,
        getloaded: false,
        error: true,
      };
    }
    case GET_BOT_CONFIG_REQUESTED:
    {
      return {
        ...state,
        getloading: true,
        getloaded: false,
      };
    }
    case GET_BOT_CONFIG_SUCCESS:
    {
      return {
        ...state,
        getloading: false,
        error: false,
        getloaded: true,
        botConfig: action.result
      };
    }
    case GET_BOT_CONFIG_FAILURE:
    {
      return {
        ...state,
        getloading: false,
        getloaded: false,
        error: true,
      };
    }
    case GET_AVATAR_CONFIG_REQUESTED:
    {
      return {
        ...state,
        getloading: true,
        getloaded: false,
      };
    }
    case GET_AVATAR_CONFIG_SUCCESS:
    {
      return {
        ...state,
        getloading: false,
        error: false,
        getloaded: true,
        avatar: action.result
      };
    }
    case GET_AVATAR_CONFIG_FAILURE:
    {
      return {
        ...state,
        getloading: false,
        getloaded: false,
        error: true,
      };
    }
    case SCRATCHCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SCRATCHCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SCRATCHCONFIG_SAVE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case SLOTCONFIG_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case SLOTCONFIG_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case SLOTCONFIG_SAVE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SCRATCH_CARD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SCRATCH_CARD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        card: action.result
      };
    }
    case GET_SCRATCH_CONFIG_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_SLOTMACHINE_ONE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SLOTMACHINE_ONE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        slot: action.result
      };
    }
    case GET_SLOTMACHINE_ONE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const uploadAppConfig = (file) => {
  const data = new FormData();
  data.append('file', file);
  return {
    types: [APPCONFIG_UPLOAD_REQUESTED, GET_HOMECONFIG_SUCCESS, GET_HOMECONFIG_FAILURE],
    promise: client => client.post('appConfigUpload', {
      data
    })
  };
};

export const appConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [APPCONFIG_SAVE_REQUESTED, APPCONFIG_SAVE_SUCCESS, APPCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME}/appconfig/add`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const homeConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [HOMECONFIG_SAVE_REQUESTED, HOMECONFIG_SAVE_SUCCESS, HOMECONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_TOURNAMENT_HOST_NAME}/homeConfig/save/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const userConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [USERCONFIG_SAVE_REQUESTED, USERCONFIG_SAVE_SUCCESS, USERCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME}/userconfig/save/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const referralConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [REFERRALCONFIG_SAVE_REQUESTED, REFERRALCONFIG_SAVE_SUCCESS, REFERRALCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME}/referralconfig/save/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const ftueConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [FTUECONFIG_SAVE_REQUESTED, FTUECONFIG_SAVE_SUCCESS, FTUECONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_TOURNAMENT_HOST_NAME}/ftue/save/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const dailyConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [DAILYCONFIG_SAVE_REQUESTED, DAILYCONFIG_SAVE_SUCCESS, DAILYCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACTIVITY_HOST_NAME}/gamification/save/daily/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const spinConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [SPINCONFIG_SAVE_REQUESTED, SPINCONFIG_SAVE_SUCCESS, SPINCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACTIVITY_HOST_NAME}/gamification/save/spin/excel?type=2`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const botConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [BOTCONFIG_SAVE_REQUESTED, BOTCONFIG_SAVE_SUCCESS, BOTCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_TOURNAMENT_HOST_NAME}/battle/save/bot`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const botUserSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [BOTUSER_SAVE_REQUESTED, BOTUSER_SAVE_SUCCESS, BOTUSER_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME}/bots/save/bots/excel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const avatarUpload = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [BOTUSER_SAVE_REQUESTED, BOTUSER_SAVE_SUCCESS, BOTUSER_SAVE_FAILURE],
    promise: () => axios({
      method: 'POST',
      url: `${APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME}/avatar/upload`,
      data: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    })
  };
};

export const getAppConfig = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GETAPPCONFIG_SAVE_REQUESTED, GETAPPCONFIG_SAVE_SUCCESS, GETAPPCONFIG_SAVE_FAILURE],
    promise: client => client.get('getAppConfig?language=english&clientId=ccAbgFftuznTHa-WLG7Fng')
  };
};

export const getHomeConfig = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GET_HOMECONFIG_REQUESTED, GETAPPCONFIG_SAVE_SUCCESS, GETAPPCONFIG_SAVE_FAILURE],
    promise: client => client.get('getHomeConfig?language=english&clientId=ccAbgFftuznTHa-WLG7Fng')
  };
};

export const getSpinConfig = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GET_SPINCONFIG_REQUESTED, GET_SPINCONFIG_SUCCESS, GET_SPINCONFIG_FAILURE],
    promise: client => client.get('getSpinConfig')
  };
};

export const botConfigList = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GET_BOT_CONFIG_REQUESTED, GET_BOT_CONFIG_SUCCESS, GET_BOT_CONFIG_FAILURE],
    promise: client => client.get('botConfigList')
  };
};

export const getAvatarConfig = (data) => {
  console.log('reducer==========================================', data);
  return {
    types: [GET_BOT_CONFIG_REQUESTED, GET_BOT_CONFIG_SUCCESS, GET_BOT_CONFIG_FAILURE],
    promise: client => client.get('getAvatarConfig')
  };
};

export const getScratchCongfig = (data) => {
  console.log('card==========================================', data);
  return {
    types: [GET_SCRATCH_CARD_REQUESTED, GET_SCRATCH_CARD_SUCCESS, GET_SCRATCH_CONFIG_FAILURE],
    promise: client => client.get('getScratchCongfig')
  };
};

export const getslotmachines = (data) => {
  console.log('slot==========================================', data);
  return {
    types: [GET_SLOTMACHINE_ONE_REQUESTED, GET_SLOTMACHINE_ONE_SUCCESS, GET_SLOTMACHINE_ONE_FAILURE],
    promise: client => client.get(`getslotmachine?value=${data}`)
  };
};
export const scratchConfigSave = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [SCRATCHCONFIG_SAVE_REQUESTED, SCRATCHCONFIG_SAVE_SUCCESS, SCRATCHCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACTIVITY_HOST_NAME}/gamification/save/spin/excel?type=3`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const slotMachineConfigSave = (file, name) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [SLOTCONFIG_SAVE_REQUESTED, SLOTCONFIG_SAVE_SUCCESS, SLOTCONFIG_SAVE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_ACTIVITY_HOST_NAME}/gamification/save/slot/machine?name=${name}`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};
