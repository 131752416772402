export const GET_ORDER_REQUESTED = 'payment/GET_ORDER_REQUESTED';
export const GET_ORDER_SUCCESS = 'payment/GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'payment/GET_ORDER_FAILURE';

export const GET_PAYMENT_REQUESTED = 'payment/GET_PAYMENT_REQUESTED';
export const GET_PAYMENT_SUCCESS = 'payment/GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILURE = 'payment/GET_PAYMENT_FAILURE';

export const ADD_BENIFICIAY_REQUESTED = 'payment/ADD_BENIFICIAY_REQUESTED';
export const ADD_BENIFICIAY_SUCCESS = 'payment/ADD_BENIFICIAY_SUCCESS';
export const ADD_BENIFICIAY_FAILURE = 'payment/ADD_BENIFICIAY_FAILURE';

export const CLEAR_BENEFICIARY_ERROR = 'payment/CLEAR_BENEFICIARY_ERROR';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  data: {},
  code: '',
  msg: '',
  status: '',
  paymentData: [],
  beneficiaryMessage:'',
  beneficiaryError:'',
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case GET_ORDER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ORDER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action && action.result,
        code: '',
        status: '200'
      };
    }
    case GET_ORDER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.code,
        msg: action && action.result && action.msg,
        status: action && action.result && action.result.status
      };
    }
    case GET_PAYMENT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PAYMENT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        paymentData: action && action.result,
        code: '',
        status: '200'
      };
    }
    case GET_PAYMENT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.code,
        msg: action && action.result && action.msg,
        status: action && action.result && action.result.status
      };
    }
    case ADD_BENIFICIAY_REQUESTED:
      {
        return {
          ...state,
          loading: true,
          loaded: false,
        };
      }
      case ADD_BENIFICIAY_SUCCESS:
      {
        console.log('successsss', action.result);
        return {
          ...state,
          loading: false,
          error: false,
          loaded: true,
          status: '200',
          beneficiaryMessage: action.result && action.result.message
        };
      }
      case ADD_BENIFICIAY_FAILURE:
      {
        console.log('3333333333333333333333333333', action.error.response.data.message);
        return {
          ...state,
          loading: false,
          loaded: false,
          error: true,
          status: action && action.error && action.error.response.data.status,
          beneficiaryError: action && action.error && action.error.response.data.message,
        };
      }
      case CLEAR_BENEFICIARY_ERROR: {
      return {
        ...state,
        beneficiaryError: '',
        beneficiaryMessage: ''
      };
    }
    default:
      return state;
  }
};

export const payInOrderAPI = () => {
  return {
    types: [GET_ORDER_REQUESTED, GET_ORDER_SUCCESS, GET_ORDER_FAILURE],
    promise: client => client.get('paymentOrderDetails', {
    })
  };
};

export const paymentTransactions = (startdate, enddate, type) => {
  return {
    types: [GET_PAYMENT_REQUESTED, GET_PAYMENT_SUCCESS, GET_PAYMENT_FAILURE],
    promise: client => client.get('paymentTransactions', {
      params : {startdate,enddate, type}
    })
  };
};

export const submitPaymentForm = (data) => {
  console.log('dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', data);
  return {
    types: [ADD_BENIFICIAY_REQUESTED, ADD_BENIFICIAY_SUCCESS, ADD_BENIFICIAY_FAILURE],
    promise: client => client.post('submitPaymentForm', {
      data
    })
  };
};

export const clearBeneficiaryError = () => {
  return {
    type: CLEAR_BENEFICIARY_ERROR,
  };
};
