const RewardsType = {
  1: 'Coins',
  2: 'Cash',
  3: 'Coins and Cash',
};


export default {
  RewardsType,
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot Password',
  USERNAME: 'Username',
  PASSWORD: 'Password',
  REGISTER: 'Register',
  NO_ACCOUNT: 'No Account? ',
  USERNAME_VALIDATION: 'Username is required',
  PASS_VALIDATION: 'Password is required',
  USERAGENT: 'Browser',
  REGISTRATION: 'Registration code will be sent via SMS. You will be redirected  to registration page to reset password.',
  SEND: 'Send',
  BACK_TO: 'Back to',
  RESET_PASSWORDS: 'Reset Password',
  CODE: 'Code',
  CONFIRM_PASSWORD: 'Confirm Password',
  ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
  DASHBOARD: 'Dashboard',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  QB_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/question_bank_upload_sample.xlsx',
  TOURNAMENT_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/tournament_upload_sample.xlsx',
  APPCONFIG_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/appconfig_upload_sample.xlsx',
  HOMECONFIG_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/homeconfig_upload_sample.xlsx',
  USERCONFIG_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/userconfig_upload_sample.xlsx',
  REFERRAL_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/referral_upload_sample.xlsx',
  FTUE_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/ftue_upload_sample.xlsx',
  DAILY_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/dailyLogin_upload_sample.xlsx',
  SPIN_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/spin_upload_sample.xlsx',
  BATTLE_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/battle_upload_sample.xlsx',
  BOT_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/bot_upload_sample.xlsx',
  BOT_USER_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/botuser_upload_sample.xlsx',
  AVATAR_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/avatar_upload_sample.xlsx',
  SCRATCH_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/scratch_upload_sample.xlsx',
  SLOTONE_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/SlotMachine1_upload_sample.xlsx',
  SLOTTWO_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/SlotMachine2_upload_sample.xlsx',
  SLOTTHREE_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/SlotMachine3_upload_sample.xlsx',
  SLOTFOUR_SAMPLE_TEMPLATE_PATH: 'https://storage.googleapis.com/ec2-us-east-1-1b-dev-contentmedia-ca-d2c/contentSampleUploads/SlotMachine4_upload_sample.xlsx',
  CONTENT_UPLOAD: 'Content Upload',
  QB: 'Question Banks',
  TOURNAMENTS: 'Tournaments',
  APP_CONFIG: 'App Config',
  Home_CONFIG: 'Home Config',
  CONFIG_UPLOAD: 'Config Upload',
  USER_CONFIG: 'User Config',
  REFERRAL_CONFIG: 'Referral Config',
  FTUE_CONFIG: 'Ftue Config',
  DAILY_CONFIG: 'Daily Streak Config',
  SPIN_CONFIG: 'Spin the Wheel Config',
  VIEW_AS: 'View As',
  BATTLE: 'Battles',
  BOT_CONFIG: 'Bot Config',
  BOT_USER: 'Bot User',
  SLOT_ONE: 'Slot Machine Gems',
  SLOT_TWO: 'Slot Machine Numbers',
  SLOT_THREE: 'Slot Machine SeaShells',
  SLOT_FOUR: 'Slot Machine Kingdom',
  SCRATCH_CARD: 'Scratch Card',
};
