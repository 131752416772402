import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody
} from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import { payInOrderAPI } from '@reducers/payment';
import Footer from '@components/common/Footer';
import topLogo from '@assets/images/gyan.png';
import {  dashboardApi } from '@reducers/login';
import { checkPayment } from '../../helpers/Utility.js';

import cn from 'classnames';
// import { CONSTANTS } from '@constants';
// import cn from 'classnames';

class PaymentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_data: {},
      userInfo: { name: 'Test', amount: -1, sponsorId:'' },
      isOpen: false,
      initialValues: {
        name: '',
        ifsc: '',
        account_number: ''
      }
    };
  }

  componentDidMount = async () => {
    
    // this.props.dashboardApi().then(checkPayment);

    var script = document.createElement('script');
    script = document.createElement('script');
    script.src = 'https://sdk.cashfree.com/js/v3/cashfree.js';
    let btn = document.getElementById('rzp-button1');
    btn.parentNode.appendChild(script);

    await this.props.payInOrderAPI();
    const data = this.props.orderData;
    let userInfo = {};
    userInfo.name = data.name;
    userInfo.sponsorId = data.sponsorId;
    userInfo.amount = data.amount //in Rs
    this.setState({ userInfo: userInfo });
  };
  //"order_id": "order_102880242lKkBJ3ZSIPR8WcOKYSJz9DBasl",
  //"payment_session_id": "session_tRLbLC8dFy9SDxvf78jcoWvPX_PrjgTiFt37fCiDYiE8jZAy8LXZX_Nmmwwy2jPcz9K3ALnMLOMbNhCjtHmJk81-ALuctk-zhxR25w3SUHJ5"
  openCheckout(orderData) {
    // this.setState({ isOpen: true });
    // let options = {
    //   key: orderData.key, // Enter the Key ID generated from the Dashboard
    //   amount: orderData.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //   currency: orderData.currency,
    //   name: 'Gyanalaya Educational Sevices Pvt. Ltd', //your business name
    //   description: orderData.description,
    //   image: topLogo,
    //   order_id: orderData.orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //   callback_url: window.location.origin + '/payment_processing',
    //   prefill: orderData.prefill, //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
    //   notes: {
    //     type: orderData.orderType,
    //   },
    //   theme: {
    //     color: '#3399cc',
    //   },
    // };
    // var rzp1 = new window.Razorpay(options);
    // rzp1.open();
    const cashfree = window.Cashfree({
      mode:"sandbox" //or production
    });
    let checkoutOptions = {
      paymentSessionId: orderData.sessionId,
      redirectTarget: "_modal" //optional ( _self, _blank, or _top)
  }
  
  cashfree.checkout(checkoutOptions)
  }

  closeModal() {
    this.setState({ isOpen: false });
  }

  validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    ifsc: Yup.string()
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code')
      .required('IFSC code is required'),
    account_number: Yup.string()
      .matches(/^\d{16}$/, 'Account number must be 16 digits')
      .required('Account number is required')
  });

  // Form submission handler
   handleSubmit = (values) => {
    console.log('Form values:', values);
  };

  render() {
    return (
      <>
        <style>{`.payment-card{margin-left:auto; margin-right:auto; text-align: center; \
        box-shadow: 0 0.25rem 0.5rem rgba(28, 32, 51, 0.1); padding:6px; \
    border: 1px solid rgba(0, 0, 0, 0.2); \
        max-width:400px } \
        #rzp-button1.hover{} \
        .payment-image{ max-width: 100%; max-width: -moz-available; max-width: -webkit-fill-available; max-width: fill-available; }\
            `}</style>
      <div className='container' style={{minHeight:'inherit'}}>
      <div className='payment-card'>
        <div>
          <img className='payment-image'
            alt=""
            src="https://th.bing.com/th/id/OIG1.E_.G0I7pxQqwX15PynvE?w=1024&h=1024&rs=1&pid=ImgDetMain"
          />
        </div>
        <div>
          <h3 style={{marginTop:'2em', marginBottom:'1px'}}>Hey!!! {this.state.userInfo.name}</h3>
          <h4 style={{marginTop:'3px', marginBottom:'4px', color:'#808080'}}>Sponser ID: {this.state.userInfo.sponsorId}</h4>
          <h5 style={{marginBottom:'2em'}}>Complete your payment of ₹ {this.state.userInfo.amount} to join us.</h5>
       
        </div>
        <div>
          <button
            style={{
              display: 'inline-block',
              outline: 'none',
              cursor: 'pointer',
              fontWeight: '500',
              borderRadius: 3,
              padding: '0 15px',
              borderRadius: 4,
              color: '#0b6bcb',
              background: 'transparent',
              lineHeight: '1.15',
              fontSize: 14,
              height: 36,
              wordSpacing: 0,
              letterSpacing: '.0892857143em',
              textDecoration: 'none',
              textTransform: 'uppercase',
              minWidth: 64,
              border: '2px solid #0b6bcb',
              textAlign: 'center',
              transition: 'background 280ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
            id="rzp-button1"
            onClick={() => this.openCheckout(this.props.orderData)}
          >
            PAY
          </button>
          <Modal isOpen={this.state.isOpen}>
        <span onClick={() => this.closeModal()}>
          <h2 style={{cursor: "pointer"}}>
            X
          </h2>
        </span>
        <ModalBody className="modalBody">
        <div className="form-container">
      <h1>Account Information Form</h1>
      <Formik
        initialValues={this.state.initialValues}
        validationSchema={() => this.validationSchema}
        onSubmit={this.handleSubmit}
      >
        <Form className="form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <Field className="form-control" type="text" id="name" name="name" />
            <ErrorMessage className="error-message" style={{color: "red"}} name="name" component="div" />
          </div>

          <div className="form-group">
            <label htmlFor="ifsc">IFSC Code:</label>
            <Field className="form-control" type="text" id="ifsc" name="ifsc" />
            <ErrorMessage className="error-message" style={{color: "red"}} name="ifsc" component="div" />
          </div>

          <div className="form-group">
            <label htmlFor="account_number">Account Number:</label>
            <Field className="form-control" type="text" id="account_number" name="account_number" />
            <ErrorMessage className="error-message" style={{color: "red"}} name="account_number" component="div" />
          </div>

          <button className="submit-button" type="submit">Submit</button>
        </Form>
      </Formik>
    </div>
        </ModalBody>
      </Modal>
        </div>
        </div>
      </div>
      <Footer/>
      </>
    );
  }
}

const mapStateToProps = state => ({
  orderData: state.payment.data,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      payInOrderAPI,
      dashboardApi
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentPage)
);
