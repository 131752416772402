export const GET_USER_KYC_REQUESTED = 'kyc/GET_USERS_BY_DATE_REQUESTED';
export const GET_USER_KYC_SUCCESS = 'kyc/GET_USERS_BY_DATE_SUCCESS';
export const GET_USER_KYC_FAILURE = 'kyc/GET_USERS_BY_DATE_FAILURE';

export const UPDATE_USER_KYC_REQUESTED = 'kyc/UPDATE_USER_KYC_REQUESTED';
export const UPDATE_USER_KYC_SUCCESS = 'kyc/UPDATE_USER_KYC_SUCCESS';
export const UPDATE_USER_KYC_FAILURE = 'kyc/UPDATE_USER_KYC_FAILURE';

export const GET_ALL_USERS_REQUESTED = 'kyc/GET_ALL_USERS_REQUESTED';
export const GET_ALL_USERS_SUCCESS = 'kyc/GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'kyc/GET_ALL_USERS_FAILURE';

export const UPDATE_USER_REQUESTED = 'kyc/UPDATE_USER_REQUESTED';
export const UPDATE_USER_SUCCESS = 'kyc/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'kyc/UPDATE_USER_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  kycDetailsList: [],
  kycLoading: false,
  updateKycLoading: false,
  totalCount: 0,
  getAllUsersLoading: false,
  userList: [],
  userListTotalCount: 0,
  updateUserLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_KYC_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        kycLoading: true
      };
    }
    case GET_USER_KYC_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        kycLoading: false,
        kycDetailsList: action.result.kycDetailsList.userKycList,
        totalCount: action.result.kycDetailsList.totalCount,
      };
    }
    case GET_USER_KYC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        kycLoading: false
      };
    }
    case UPDATE_USER_KYC_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        updateKycLoading: true
      };
    }
    case UPDATE_USER_KYC_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        updateKycLoading: false,
      };
    }
    case UPDATE_USER_KYC_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        updateKycLoading: false
      };
    }
    case GET_ALL_USERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        getAllUsersLoading: true
      };
    }
    case GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        getAllUsersLoading: false,
        userList: action.result.userList,
        userListTotalCount: action.result.totalCount
      };
    }
    case GET_ALL_USERS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        getAllUsersLoading: false
      };
    }
    case UPDATE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        updateUserLoading: true
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        updateUserLoading: false,
      };
    }
    case UPDATE_USER_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        loaded: true,
        updateUserLoading: false
      };
    }
    default:
      return state;
  }
};

export const getUserKycList = (data) => {
  return {
    types: [GET_USER_KYC_REQUESTED, GET_USER_KYC_SUCCESS, GET_USER_KYC_FAILURE],
    promise: client => client.get(`getUserKycList/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&panSearchKey=${data.panSearchKey}&statusSearchKey=${data.statusSearchKey}`)
  };
};

export const updateUserKyc = (data) => {
  return {
    types: [UPDATE_USER_KYC_REQUESTED, UPDATE_USER_KYC_SUCCESS, UPDATE_USER_KYC_FAILURE],
    promise: client => client.post('updateUserKyc', {
      data
    })
  };
};

export const getAllUsers = (data) => {
  console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa', data);
  return {
    types: [GET_ALL_USERS_REQUESTED, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_FAILURE],
    promise: client => client.get(`getAllUsers/${data.itemsPerPage}/${data.pageNo}?searchByMobileNo=${data.searchKey}`)
  };
};

export const updateUser = (data) => {
  return {
    types: [UPDATE_USER_REQUESTED, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    promise: client => client.post('updateUser', {
      data
    })
  };
};
