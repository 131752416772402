const TITLE = '';

export default {
  // CURRENT_CONTENT_HOST_NAME: 'http://localhost:8082/OnMobileContentService',
  // CURRENT_ACCOUNT_HOST_NAME: 'http://localhost:8081/OnMobileUserService',
  // CURRENT_TOURNAMENT_HOST_NAME: 'http://localhost:8086/OnMobileTournamentService',
  // CURRENT_TRANSACTION_HOST_NAME: 'http://localhost:8084/OnMobileTransactionsService',
  // CURRENT_ACTIVITY_HOST_NAME: 'http://localhost:8083/OnMobileActivityService',
  LOGIN: `${TITLE} Login`,
  SIGNUP: `${TITLE} Sign up`,
  RESET_PASSWORD: `${TITLE} Reset password`,
  BASE_URL: process.env.PUBLIC_URL,
  PAGE_SIZE: 5,
  ITEM_PER_PAGE_5: 5,
  ITEM_PER_PAGE_10: 10,
  ORDER_ASC: 'asc',
  ORDER_DESC: 'desc',
  INTERVAL_TIME: 600000,
  HOST_TYPE: 'localhost',
  BROWSER: 'Browser',
  ERROR: 'ERROR',
  STUDENT: 'Student',
};
