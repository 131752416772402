import { APP_CONFIG } from '@constants';

const upload = require('superagent');

export const TOURNAMENT_UPLOAD_REQUESTED = 'tournamentUpload/TOURNAMENT_UPLOAD_REQUESTED';
export const TOURNAMENT_UPLOAD_SUCCESS = 'tournamentUpload/TOURNAMENT_UPLOAD_SUCCESS';
export const TOURNAMENT_UPLOAD_FAILURE = 'tournamentUpload/TOURNAMENT_UPLOAD_FAILURE';

export const TOURNAMENT_SAVE_REQUESTED = 'tournamentUpload/TOURNAMENT_SAVE_REQUESTED';
export const TOURNAMENT_SAVE_SUCCESS = 'tournamentUpload/TOURNAMENT_SAVE_SUCCESS';
export const TOURNAMENT_SAVE_FAILURE = 'tournamentUpload/TOURNAMENT_SAVE_FAILURE';

export const GET_QUESTIONS_REQUESTED = 'tournamentUpload/GET_QUESTIONS_REQUESTED';
export const GET_QUESTIONS_SUCCESS = 'tournamentUpload/GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAILURE = 'tournamentUpload/GET_QUESTIONS_FAILURE';

export const TOURNAMENT_UPDATE_REQUESTED = 'tournamentUpload/TOURNAMENT_UPDATE_REQUESTED';
export const TOURNAMENT_UPDATE_SUCCESS = 'tournamentUpload/TOURNAMENT_UPDATE_SUCCESS';
export const TOURNAMENT_UPDATE_FAILURE = 'tournamentUpload/TOURNAMENT_UPDATE_FAILURE';

export const BATTLE_UPDATE_REQUESTED = 'battleUpload/BATTLE_UPDATE_REQUESTED';
export const BATTLE_UPDATE_SUCCESS = 'battleUpload/BATTLE_UPDATE_SUCCESS';
export const BATTLE_UPDATE_FAILURE = 'battleUpload/BATTLE_UPDATE_FAILURE';

export const DELETE_TOURNAMENT_REQUESTED = 'tournamentUpload/DELETE_TOURNAMENT_REQUESTED';
export const DELETE_TOURNAMENT_SUCCESS = 'tournamentUpload/DELETE_TOURNAMENT_SUCCESS';
export const DELETE_TOURNAMENT_FAILURE = 'tournamentUpload/DELETE_TOURNAMENT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  questionsDetails: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOURNAMENT_UPLOAD_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TOURNAMENT_UPLOAD_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case TOURNAMENT_UPLOAD_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case TOURNAMENT_SAVE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TOURNAMENT_SAVE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case TOURNAMENT_SAVE_FAILURE:
    {
      console.log('error------>', action.result);
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_QUESTIONS_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_QUESTIONS_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        questionsDetails: action.result
      };
    }
    case GET_QUESTIONS_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case TOURNAMENT_UPDATE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TOURNAMENT_UPDATE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case TOURNAMENT_UPDATE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case BATTLE_UPDATE_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case BATTLE_UPDATE_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
      };
    }
    case BATTLE_UPDATE_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_TOURNAMENT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_TOURNAMENT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true
      };
    }
    case DELETE_TOURNAMENT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const uploadTournament = (file) => {
  const data = new FormData();
  data.append('file', file);
  return {
    types: [TOURNAMENT_UPLOAD_REQUESTED, TOURNAMENT_UPLOAD_SUCCESS, TOURNAMENT_UPLOAD_FAILURE],
    promise: client => client.post('tournamentUpload', {
      data
    })
  };
};

// export const saveTournament = (data) => {
//   return {
//     types: [TOURNAMENT_UPLOAD_REQUESTED, TOURNAMENT_UPLOAD_SUCCESS, TOURNAMENT_UPLOAD_FAILURE],
//     promise: client => client.post('tournamentSave', {
//       data
//     })
//   };
// };

export const saveTournament = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [TOURNAMENT_UPLOAD_REQUESTED, TOURNAMENT_UPLOAD_SUCCESS, TOURNAMENT_UPLOAD_FAILURE],
    promise: () => upload
    // .post('http://localhost:8084/RMFConsumer/api/upload/timesheet/docs')
      .post(`${APP_CONFIG.CURRENT_TOURNAMENT_HOST_NAME}/tournament/saveExcel`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const getQuestionDetails = (data) => {
  return {
    types: [GET_QUESTIONS_REQUESTED, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    promise: client => client.post('getQuestionDetails', {
      data
    })
  };
};


export const updateTournament = (data) => {
  return {
    types: [TOURNAMENT_UPDATE_REQUESTED, TOURNAMENT_UPDATE_SUCCESS, TOURNAMENT_UPDATE_FAILURE],
    promise: client => client.post('updateTournament', {
      data
    })
  };
};

export const saveBattle = (file) => {
  const accessToken = window.localStorage.getItem('access_token');
  return {
    types: [BATTLE_UPDATE_REQUESTED, BATTLE_UPDATE_SUCCESS, BATTLE_UPDATE_FAILURE],
    promise: () => upload
      .post(`${APP_CONFIG.CURRENT_TOURNAMENT_HOST_NAME}/battle/excel/upload`)
      .withCredentials()
      .set({ has_auth_token: 1 })
      .set('Authorization', `Bearer ${accessToken}`)
      .attach('file', file)
  };
};

export const deleteTournaments = (data, instanceIds) => {
  return {
    types: [DELETE_TOURNAMENT_REQUESTED, DELETE_TOURNAMENT_SUCCESS, DELETE_TOURNAMENT_FAILURE],
    promise: client => client.post(`deleteTournament?instanceIds=${instanceIds}`, {
      data
    })
  };
};
