import React, { Fragment } from 'react';
// import cn from 'classnames';
// import Styles from './index.module.css';

function footer() {
  return (
    <Fragment>
      <div id="footer" className="termSection">
        {/* <span className="d-flex align-items-end justify-content-end mr-20 text-small">
          <span className="">
            &copy;
          </span>
          Copyright&nbsp;&nbsp;Gyanalaya, All Rights Reserved
        </span> */}
        <div className="termsBlock">
          <div className="container">
            <div className="row">
              <div
                className="col-sm"
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                <a href="/TermsOfUse">Terms of Use </a>
                <span>|</span>
                <a href="/PrivacyPolicy"> Privacy Policy </a>
                <span>|</span>
                <a href="/PaymentTerms"> Payment Terms </a>
                <span>|</span>
                <a href="/refund-cancellation"> Refund & Cancellation </a>
                <span>|</span>
                <a href="/Disclaimer"> Disclaimer </a>
                {/* <span>|</span>
                <a href="/terms-of-use.asp"> Public Notice</a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="footer-copyright">
                  <p className="pt-0">
                    Gyanalaya Educational Sevices Private Limited | GESPL<br />
                    (CIN: U80904BR2015PTC023638)<br />
                    Registered &amp; Corporate Office : Suratganj, Ward No. 17,
                    Madhubani (Bihar)- 847235 India{' '}
                  </p>
                </div>
              </div>
              <div className="col-sm full-width">
                <div className="footer-social footer-social-2">
                  <ul>
                    <li>
                      <a
                        href="https://api.whatsapp.com/send?phone=919835284106&text=Hi"
                        title="Whatsapp"
                        target="_blank"
                      >
                        <i className="fa fa-whatsapp" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/s/"
                        title="Telegram"
                        target="_blank"
                      >
                        <i className="fa fa-paper-plane" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/"
                        title="Facebook"
                        target="_blank"
                        className="fc"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/"
                        title="Twitter "
                        target="_blank"
                        className="tc"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/"
                        title="Instagram "
                        target="_blank"
                        className="insta"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="  https://www.linkedin.com/company/"
                        title="Linkedin"
                        className="inc"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/"
                        title="Youtube"
                        target="_blank"
                        className="yc"
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="" target="_blank" title="Blog" className="bc">
                        <i className="fa fa-rss-square" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.pinterest.com/"
                        target="_blank"
                        title="Blog"
                        className="bc"
                      >
                        <i className="fa fa-pinterest" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-copyright full">
              <p>
                {' '}
                Gyanalaya Educational Sevices Pvt. Ltd. &copy; All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
        <div
          className="text-center"
          role="alert"
          style={{
            background: '#72c02c',
            color: '#fff',
            marginBottom: 0,
            padding: 5,
          }}
        >
          *Use of this website is subject to express{' '}
          <a
            href="/TermsOfUse"
            style={{ textDecoration: 'underline', color: '#fff' }}
          >
            terms of use
          </a>. By continuing, you agree to abide by these terms*
        </div>
      </div>
    </Fragment>
  );
}

export default footer;
