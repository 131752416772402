import APP_CONFIG from './appConfig';

const baseMeta = {
  charset: 'utf-8',
  keywords: 'Content',
};

export default {
  '/login': {
    title: {
      EN: 'Login',
    },
    description: 'Welcome to Content',
    canonical: `${APP_CONFIG.BASE_URL}/login`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Login'),
      },
    },
  },
  '/dashboard': {
    title: {
      EN: 'Dashboard'
    },
    description: 'Application Dashboard',
    canonical: `${APP_CONFIG.BASE_URL}/dashboard`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Dashboard, Brief details, At a glance'),
      },
    },
  },
  '/TermsOfUse': {
    title: {
      EN: 'Terms Of Use', MR: 'वापरण्याच्या अटी', HI: 'उपयोग की शर्तें', FR: 'Terms Of Use'
    },
    description: '',
    canonical: `${APP_CONFIG.BASE_URL}/TermsOfUse`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Terms Of Use'),
      },
    },
  },
  '/PrivacyPolicy': {
    title: {
      EN: 'Privacy Policy', MR: 'वापरण्याच्या अटी', HI: 'उपयोग की शर्तें', FR: 'Privacy Policy'
    },
    description: '',
    canonical: `${APP_CONFIG.BASE_URL}/PrivacyPolicy`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Privacy Policy'),
      },
    },
  },
  '/PaymentTerms': {
    title: {
      EN: 'Payment Terms', MR: 'वापरण्याच्या अटी', HI: 'उपयोग की शर्तें', FR: 'PaymentTerms'
    },
    description: '',
    canonical: `${APP_CONFIG.BASE_URL}/PaymentTerms`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Payment Terms'),
      },
    },
  },
  '/refund-cancellation': {
    title: {
      EN: 'Refund Cancellation', MR: 'वापरण्याच्या अटी', HI: 'उपयोग की शर्तें', FR: 'Refund Cancellation'
    },
    description: '',
    canonical: `${APP_CONFIG.BASE_URL}/refund-cancellation`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Refund Cancellation'),
      },
    },
  },
  '/Disclaimer': {
    title: {
      EN: 'Disclaimer', MR: 'वापरण्याच्या अटी', HI: 'उपयोग की शर्तें', FR: 'Disclaimer'
    },
    description: '',
    canonical: `${APP_CONFIG.BASE_URL}/Disclaimer`,
    meta: {
      charset: baseMeta.charset,
      name: {
        keywords: baseMeta.keywords.concat('Disclaimer'),
      },
    },
  },
};
