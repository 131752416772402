
export const TOURNAMENTMASTER_GET_REQUESTED = 'tournamentMaster/TOURNAMENTMASTER_GET_REQUESTED';
export const TOURNAMENTMASTER_GET_SUCCESS = 'tournamentMaster/TOURNAMENTMASTER_GET_SUCCESS';
export const TOURNAMENTMASTER_GET_FAILURE = 'tournamentMaster/TOURNAMENTMASTER_GET_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  code: '',
  msg: '',
  status: '',
  master: []
};

export default (state = initialState, action) => {
  console.log(action.result, 'result');
  switch (action.type) {
    case TOURNAMENTMASTER_GET_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case TOURNAMENTMASTER_GET_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        tournamentMasterList: action.result.tournamentmasterList.tournamentmasterDetails
      };
    }
    case TOURNAMENTMASTER_GET_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getTournamentMaster = (data) => {
  console.log('reducer', data);
  return {
    types: [TOURNAMENTMASTER_GET_REQUESTED, TOURNAMENTMASTER_GET_SUCCESS, TOURNAMENTMASTER_GET_FAILURE],
    promise: client => client.get(`getTournamentMaster/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&filterStatus=${data.filterStatus}&startDate=${data.startDate}&endDate=${data.endDate}&daily=${data.daily}&rewardType=${data.rewardType}`)
    // promise: client => client.get(`getTournamentMaster/${data.itemsPerPage}/${data.pageNo}?searchKey=${data.searchKey}&filterStatus=${data.filterStatus}&startDate=${data.startDate}&endDate=${data.endDate}&daily=${data.daily}&rewardType=${data.rewardType}`)
  };
};
