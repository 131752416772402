import React from 'react';
import Styles from './App.module.css';

const AppBody = (props) => {
  return (
    <div className={Styles.appContainer}>
      {props.children}
    </div>
  );
};


export default AppBody;
